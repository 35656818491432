var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('VAdministrationMain',[_c('VBreadcrumbs',{staticClass:"v-breadcrumbs--margin-bottom",attrs:{"routes":[
            {
                name: 'administration.projects.edit',
                params: { id: _vm.currentProjectId },
            },
            {
                name: 'administration.sections.edit',
                params: { id: _vm.currentSectionId },
            },
            {
                name: 'administration.floors.edit',
                params: { id: _vm.currentFloorId },
            },
        ]}}),_c('VAdministrationHeader',[_c('VPageTitle',{attrs:{"has-back-button":"","secondary":""}})],1),_c('VAdministrationWidget',[_c('ValidationObserver',{attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit: veeSubmitHandler }){return [_c('VAdministrationForm',{on:{"submit":function($event){return veeSubmitHandler(_vm.handleSubmit)}}},[_c('ValidationProvider',{attrs:{"rules":"required","name":_vm.$t('apartmentName')},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('VFormField',{attrs:{"label":_vm.$t('apartmentName'),"error-text":errors[0]}},[_c('VInput',{attrs:{"type":"text"},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}})],1)]}}],null,true)}),(_vm.floors.length)?_c('ValidationProvider',{attrs:{"rules":"required","name":_vm.$t('floor')},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('VFormField',{attrs:{"label":_vm.$t('floor'),"error-text":errors[0]}},[_c('VSelect',{attrs:{"label":"name","track-by":"id","options":_vm.floors,"reduce":(x) => x?.id},model:{value:(_vm.model.floorId),callback:function ($$v) {_vm.$set(_vm.model, "floorId", $$v)},expression:"model.floorId"}})],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.floorId),expression:"model.floorId"}],attrs:{"type":"hidden"},domProps:{"value":(_vm.model.floorId)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.model, "floorId", $event.target.value)}}})]}}],null,true)}):_vm._e(),_c('VButton',{attrs:{"type":"submit","color":"primary","loading":_vm.isLoading}},[_vm._v(_vm._s(_vm.$t('save')))])],1)]}}])})],1),_c('VPoppedAlert',{ref:"poppedAlert"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }